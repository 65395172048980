import logo from './logo.png';
import alternative from './alternative.jpg';
import login1 from './login-01.jpg';
import login2 from './login-02.jpg';
import login3 from './login-03.jpg';
import login4 from './login-04.jpg';
import login5 from './login-05.jpg';
import livechat from './livechat.jpg';
import whatsapp from './whatsapp.jpg';

function App() {
  return (
    <div className="main-page">
      <div className="container">
        <header className="header-page">
          <div className="head-wrapper">
            <div className="brand-logo">
              <span className="t">JATENGTOTO</span>
              <div className="head-logo">
                <img src={logo} width="140" height="56" alt="JATENGTOTO" className="logo"/>
              </div>
            </div>
          </div>
        </header>
        <main className="body-page">
          <p style={{textAlign:'center',margin:'0'}}>Selamat datang di JatengToto, situs resmi dan terpercaya untuk permainan toto dan lotere di Jawa Tengah.</p>
          <p className="body-summary">Pilih Akses Login yang diinginkan:</p>
          <ul className="navbar-menu">
            <li>
              <a href="https://rebrand.ly/skh2s8h" className="nav-link" aria-current="page">
                <div className="menu-item">
                  <img src={login1} width="100" height="100" className="nav-icon" alt="JATENGTOTO LOGIN 01"/>
                  <div className="menu-summary">
                    <p className="menu-title">LOGIN [1]</p>
                    <p className="menu-desc">Masuk dan Mainkan Sekarang</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="https://tinyurl.com/2nmxpac2" className="nav-link" aria-current="page">
                <div className="menu-item">
                  <img src={login2} width="100" height="100" className="nav-icon" alt="JATENGTOTO LOGIN 02"/>
                  <div className="menu-summary">
                    <p className="menu-title">LOGIN [2]</p>
                    <p className="menu-desc">Masuk dan Mainkan Sekarang</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="https://fegr.short.gy/BeZjc3" className="nav-link" aria-current="page">
                <div className="menu-item">
                  <img src={login3} width="100" height="100" className="nav-icon" alt="JATENGTOTO LOGIN 03"/>
                  <div className="menu-summary">
                    <p className="menu-title">LOGIN [3]</p>
                    <p className="menu-desc">Masuk dan Mainkan Sekarang</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="https://go.click.ly/vpbRF" className="nav-link" aria-current="page">
                <div className="menu-item">
                  <img src={login4} width="100" height="100" className="nav-icon" alt="JATENGTOTO LOGIN 04"/>
                  <div className="menu-summary">
                    <p className="menu-title">LOGIN [4]</p>
                    <p className="menu-desc">Masuk dan Mainkan Sekarang</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="https://bit.ly/4c4oNLP" className="nav-link" aria-current="page">
                <div className="menu-item">
                  <img src={login5} width="100" height="100" className="nav-icon" alt="JATENGTOTO LOGIN 05"/>
                  <div className="menu-summary">
                    <p className="menu-title">LOGIN [5]</p>
                    <p className="menu-desc">Masuk dan Mainkan Sekarang</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="https://heylink.me/LoginJatengtoto/" className="nav-link" aria-current="page">
                <div className="menu-item">
                  <img src={alternative} width="100" height="100" className="nav-icon" alt="JATENGTOTO HEYLINK ALTERNATIF"/>
                  <div className="menu-summary">
                    <p className="menu-title">ALTERNATIF</p>
                    <p className="menu-desc">Masuk dan Mainkan Sekarang</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="https://wa.me/6281937297300?text=Helloo..%20Admin%20Jateng" className="nav-link" aria-current="page">
                <div className="menu-item">
                  <img src={whatsapp} width="100" height="100" className="nav-icon" alt="JATENGTOTO CHAT WHATSAPP"/>
                  <div className="menu-summary">
                    <p className="menu-title">WHATSAPP</p>
                    <p className="menu-desc">Support Chat via Whatsapp</p>
                  </div>
                </div>
              </a>
            </li>
            <li>
              <a href="https://direct.lc.chat/17743872/" className="nav-link" aria-current="page">
                <div className="menu-item">
                  <img src={livechat} width="100" height="100" className="nav-icon" alt="JATENGTOTO LIVE CHAT"/>
                  <div className="menu-summary">
                    <p className="menu-title">LIVE CHAT NOW</p>
                    <p className="menu-desc">Support Chat via LiveChat</p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
          <h1 style={{textAlign:'center',margin:0,fontSize:'18px',borderBottom:'1px solid #e5e7eb',marginBottom:'8px'}}>JATENG TOTO BANDAR TERPERCAYA</h1>
          <p style={{textAlign:'center',margin:0}}>
            Bergabunglah dengan ribuan pemain lain di Jateng Toto dan rasakan sensasi bermain toto yang menyenangkan dan menguntungkan. Jateng Toto adalah pilihan tepat untuk Anda yang mencari pengalaman bermain toto yang terpercaya dan profesional di Jawa Tengah.  Nikmati pengalaman bermain yang aman, nyaman, dan menyenangkan dengan berbagai pilihan permainan toto yang menarik.
          </p>
        </main>
        <footer className="footer-page" style={{marginTop:'12px'}}>
          <p className="author-copyright">&copy;2024 JATENG TOTO GROUP. All right reserved.</p>
        </footer>
      </div>
    </div>
  );
}

export default App;
